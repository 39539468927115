import { clsx } from 'clsx';

import { Spinner } from '@/client/design-system/components/spinner';
import RightArrow from '@/client/design-system/icons/arrow-right.svg';
import CircleCheck from '@/client/design-system/icons/circle-check.svg';

interface ResendButtonProps {
  loading?: boolean;
  disabled?: boolean;
  success?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ResendButton = ({ loading, disabled, success, onClick }: ResendButtonProps) => {
  return (
    <button
      type='button'
      onClick={onClick}
      className={clsx(
        'flex items-center gap-0.5 text-small-plus',
        success ? 'text-positive pointer-events-none' : disabled ? 'text-blue/40 pointer-events-none' : 'text-blue'
      )}
    >
      <span>{success ? 'Sent' : 'Resend'}</span>
      {success ? (
        <CircleCheck className='size-4' />
      ) : loading ? (
        <Spinner size={16} />
      ) : (
        <RightArrow className='size-4' />
      )}
    </button>
  );
};
