export enum OAuthError {
  AccountAlreadyLinked = 'account_already_linked',
  Adult = 'adult',
  Child = 'child',
  InvalidAccountState = 'invalid_account_state', // TODO: Fix E-2138, then we can remove this.
  NetworkError = 'network_error',
  NoMinecraftProfile = 'no_minecraft',
  NotAuthenticated = 'not_authenticated',
  ProviderAuthenticationFailed = 'provider_authentication_failed',
  ProviderSignUpForbidden = 'provider_signup_forbidden',
  UserNotFound = 'user_not_found',
  UserUpdateFailed = 'user_update_failed',
}
