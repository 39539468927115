export enum OAuthLinkProvider {
  Facebook = 'facebook',
  Google = 'google',
  Minecraft = 'minecraft',
  Roblox = 'roblox',
}

export enum OAuthLoginProvider {
  Facebook = 'facebook',
  Google = 'google',
  Minecraft = 'minecraft',
}

export enum OAuthSignupProvider {
  Facebook = 'facebook',
  Google = 'google',
}
