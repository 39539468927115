import { type z } from 'zod';

import { TurnstileError, turnstileFetch } from '@/client/core/utils/turnstile';
import {
  type SendContactVerificationRequestData,
  type sendContactVerificationRequestSchema,
} from '@/client/features/signup/contact/api/send';
import {
  type verifyPhoneRequestSchema,
  type VerifyPhoneResponseData,
} from '@/client/features/signup/contact/api/verify';
import { getValidPhone } from '@/legacy/lib/helpers';

export const validateValidPhone = (
  value?: string,
  error: string = 'Invalid number. Please enter a 10-digit phone number.'
) => (getValidPhone(value) ? true : error);

export const tryGetValidPhone = (phone: string) => {
  if (!phone) {
    return phone;
  }

  const e164Phone = phone.startsWith('+') ? phone : '+1' + phone;
  return getValidPhone(e164Phone) ?? phone;
};

export const formatPhone = (phone: string, previousPhone: string) => {
  if (!phone) {
    return phone;
  }

  const stripped = phone.replace(/[^\d]/g, '');

  if (!previousPhone || phone.length !== previousPhone.length) {
    if (stripped.length < 4) {
      return stripped;
    }
    if (stripped.length < 7) {
      return `(${stripped.slice(0, 3)}) ${stripped.slice(3)}`;
    }
    return `(${stripped.slice(0, 3)}) ${stripped.slice(3, 6)}-${stripped.slice(6, 10)}`;
  }
  return phone.slice(0, 14);
};

export const registerContactDetails = async ({
  phone,
  turnstileToken,
}: {
  phone: string;
  turnstileToken: undefined | string;
}): Promise<SendContactVerificationRequestData> => {
  try {
    const res = await turnstileFetch<z.infer<typeof sendContactVerificationRequestSchema>>('/api/signup/auth/send', {
      method: 'POST',
      body: {
        phone,
      },
      turnstile: turnstileToken,
    });

    return await res.json();
  } catch (e) {
    if (e instanceof TurnstileError) {
      throw e;
    }

    return {
      success: false,
      detail: 'Network connectivity issue.',
    };
  }
};

export const verifyPhone = async ({
  code,
  phone,
  turnstileToken,
}: {
  code: string;
  phone: string;
  turnstileToken: string;
}): Promise<VerifyPhoneResponseData> => {
  try {
    const res = await turnstileFetch<z.infer<typeof verifyPhoneRequestSchema>>('/api/signup/auth/verify', {
      method: 'POST',
      body: {
        code,
        phone,
      },
      turnstile: turnstileToken,
    });

    return await res.json();
  } catch (e) {
    if (e instanceof TurnstileError) {
      throw e;
    }

    return {
      success: false,
      detail: 'Network connectivity issue.',
    };
  }
};
