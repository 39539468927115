import { clsx } from 'clsx';
import { type FieldPath, type FieldValues } from 'react-hook-form';

import { BasePinInput, type BasePinInputProps } from '@/client/design-system/components/base/fields/base-pin-input';

interface CodeInputProps<TFormValues extends FieldValues, TFieldName extends FieldPath<TFormValues>>
  extends Omit<BasePinInputProps<TFormValues, TFieldName>, 'action' | 'actionText' | 'numInputs'> {
  resendButton?: BasePinInputProps<TFormValues, TFieldName>['actionButton'];
  success?: boolean;
}

export const PinInput = <
  N extends string,
  TFormValues extends FieldValues & Record<N, string>,
  TFieldName extends FieldPath<TFormValues> & N,
>({
  id,
  label,
  placeholder,
  register,
  control,
  setValue,
  watch,
  error,
  success,
  hideError,
  disabled,
  autoFocus,
  resendButton,
  ...props
}: CodeInputProps<TFormValues, TFieldName>) => {
  return (
    <BasePinInput
      id={id}
      label={label}
      placeholder={placeholder}
      numInputs={4}
      actionButton={resendButton}
      register={register}
      control={control}
      setValue={setValue}
      watch={watch}
      containerClassName='flex flex-col gap-1.5'
      wrapperClassName='grid grid-cols-4 gap-2'
      labelClassName='text-small-plus text-secondary'
      inputClassName={clsx(
        'h-[55px] rounded-[10px] text-large-mono font-mono text-center text-primary outline outline-1 -outline-offset-1 transition-all placeholder:text-tertiary',
        error
          ? 'outline-red focus:ring-[1.5px] focus:ring-red/40 focus:ring-offset-page-item-surface focus:ring-offset-[1.5px] caret-red bg-red/10 text-destructive'
          : `${
              success ? 'outline-green-light-1/40' : 'outline-black/10 hover:outline-black/15 caret-control-background'
            } bg-page-item-surface focus:outline-blue focus:ring-[1.5px] focus:ring-blue/40 focus:ring-offset-page-item-surface focus:ring-offset-[1.5px]`
      )}
      errorClassName='text-small-plus text-accent-red'
      error={error}
      hideError={hideError}
      disabled={disabled}
      autoFocus={autoFocus}
      autoComplete='one-time-code'
      {...props}
    />
  );
};
