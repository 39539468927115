import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import type { AsaTaskInfo } from '@/client/features/asa/task-info';
import type { OAuthLinkProvider } from '@/shared/oauth/providers';

import { USERNAME_MAX_LENGTH } from './constants';

export const validateEmail = (email: string) => {
  if (!email) return true;
  if (email.length > 254) return false;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const cleanUsername = (username: string): string => {
  // remove all leading and trailing underscores
  username = username.replace(/^_+|_+$/g, '');

  // remove all non-alphanumeric or underscore characters
  username = username.replace(/[^a-zA-Z0-9_]/g, '');

  // contains maximum of 1 underscore, remove all subsequent underscores
  username = username.replace(/\_/g, (c, i, t) => (t.indexOf(c) === i ? c : ''));

  // length must be >= 2 characters
  // override with "player_xxxx" if not the case, collision will still be handled by oauth
  if (username.length < 2) username = 'player_' + (Math.floor(Math.random() * 10000) + 10000).toString().substring(1);

  // trim the username to 20 characters
  // length operation: if the trimmed username is not unique, 4 random digits will REPLACE the last 4 chars
  // this is performed at on oauth
  username = username.substring(0, USERNAME_MAX_LENGTH);

  return username;
};

export const validateUsername = (username: any) => {
  if (!username) return false;
  if (username.length < 2 || username.length > 20) return false;
  const re = /^[a-zA-Z0-9]+(_[a-zA-Z0-9]+)*$/; // letters, numbers, and underscore only; underscore not at the end

  return re.test(String(username).toLowerCase());
};

const phoneUtil = PhoneNumberUtil.getInstance();

export const validatePhoneWithFallbackToUS = (phone: string): boolean => {
  return validatePhone(phone) || validatePhone(phone, 'US');
};

export const validatePhone = (phone: string, region?: string): boolean => {
  try {
    const number = phoneUtil.parse(phone, region);
    return phoneUtil.isValidNumber(number);
  } catch {
    return false;
  }
};

export const parsePhoneWithFallbackToUS = (phone: string) => {
  return parsePhone(phone) || parsePhone(phone, 'US') || phone;
};

const parsePhone = (phone: string, region?: string) => {
  try {
    const parsedNumber = phoneUtil.parse(phone, region);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
  } catch {
    return undefined;
  }
};

export const getValidPhone = (phone?: null | string) => {
  if (!phone) {
    return null;
  }

  try {
    const number = phoneUtil.parse(phone);
    const valid = phoneUtil.isValidNumber(number);

    if (!valid) {
      return null;
    }

    return phoneUtil.format(number, PhoneNumberFormat.E164);
  } catch (error: any) {
    return null;
  }
};

export const kFormatter = (num: number) => {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1) as any) + 'k' : num;
};

export const numberWithCommas = (x: number) => {
  const parts = x.toString().split('.');
  parts[0] = parts[0]!.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const getAge = (timestamp: string): number => {
  const ageDifMs = Date.now() - Date.parse(timestamp);
  const ageDate = new Date(ageDifMs);
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);

  return age;
};

export const getTimeUntilDate = (timestamp: string | number | Date, timeFrame = 'seconds') => {
  if (!timestamp) return;

  const d = new Date(timestamp);
  const now = new Date();
  const delta = Math.abs((d as any) - (now as any)) / 1000;

  let time;

  switch (timeFrame) {
    case 'years':
      time = Math.abs(d.getFullYear() - now.getFullYear());
      break;
    case 'months':
      time = Math.abs(d.getFullYear() - now.getFullYear()) * 12;
      time -= now.getMonth();
      time += d.getMonth();
      time = Math.abs(time);
      break;
    case 'days':
      time = Math.floor(delta / 86400);
      break;
    case 'hours':
      time = Math.floor(delta / 3600);
      break;
    case 'minutes':
      time = Math.floor(delta / 60);
      break;
    case 'seconds':
      time = delta;
      break;
  }

  return time;
};

export const getTimeBetweenDates = (
  timestamp1: string | number | Date,
  timestamp2: string | number | Date,
  timeFrame = 'seconds'
) => {
  if (!timestamp1 || !timestamp2) return;

  const d1 = new Date(timestamp1);
  const d2 = new Date(timestamp2);
  const delta = Math.abs((d2 as any) - (d1 as any)) / 1000;

  let time;

  switch (timeFrame) {
    case 'years':
      break;
    case 'months':
      time = Math.abs(d2.getFullYear() - d1.getFullYear()) * 12;
      time -= d1.getMonth();
      time += d2.getMonth();
      // FIXME: Does this work? Where is 'months' variable?
      // @ts-expect-error
      time = time <= 0 ? 0 : months;
      break;
    case 'days':
      time = Math.floor(delta / 86400);
      break;
    case 'hours':
      time = Math.floor(delta / 3600);
      break;
    case 'minutes':
      time = Math.floor(delta / 60);
      break;
    case 'seconds':
      time = delta;
      break;
  }

  return time;
};

export const formatAMPM = (date: string | number | Date) => {
  const d = new Date(date);
  let hours = d.getHours();
  let minutes: number | string = d.getMinutes();
  const ampm = hours >= 12 ? ' pm' : ' am';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  if (minutes !== 0) {
    minutes = `:${minutes < 10 ? '0' + minutes : minutes}`;
  } else {
    minutes = '';
  }
  const strTime = hours + minutes + ampm;

  return strTime;
};

export const getFormattedTime = (date: string | number | Date) => {
  const d = new Date(date);

  return formatAMPM(d);
};

export const getFormattedDateAndTime = (date: string | number | Date) => {
  const d = new Date(date);
  const month = d.getMonth() + 1;
  const day = d.getDate();
  const time = formatAMPM(d);

  return `${month}/${day} @ ${time}`;
};

export const getFormattedTimeUntilDate = (date: string | number | Date) => {
  const d = new Date(date);
  const yearsUntilDue = getTimeUntilDate(d, 'years') as number;
  const monthsUntilDue = getTimeUntilDate(d, 'months') as number;
  const daysUntilDue = getTimeUntilDate(d, 'days') as number;
  const hoursUntilDue = getTimeUntilDate(d, 'hours') as number;
  const minutesUntilDue = getTimeUntilDate(d, 'minutes') as number;
  const secondsUntilDue = getTimeUntilDate(d, 'seconds') as number;

  if (monthsUntilDue >= 12) {
    return `${yearsUntilDue} year${yearsUntilDue > 1 ? 's' : ''}`;
  } else if (daysUntilDue >= 30) {
    return `${monthsUntilDue} month${monthsUntilDue > 1 ? 's' : ''}`;
  } else if (hoursUntilDue >= 24) {
    return `${daysUntilDue} day${daysUntilDue > 1 ? 's' : ''}`;
  } else if (minutesUntilDue >= 60) {
    return `${hoursUntilDue} hour${hoursUntilDue > 1 ? 's' : ''}`;
  } else if (secondsUntilDue >= 60) {
    return `${minutesUntilDue} minute${minutesUntilDue > 1 ? 's' : ''}`;
  } else {
    return `${secondsUntilDue} second${secondsUntilDue > 1 ? 's' : ''}`;
  }
};

export const daysAgo = (n: number) => new Date(Date.now() - n * 24 * 60 * 60 * 1000).toDateString();

// TODO: Nuke this. Aside from the ah, implementation. See E-2145
export const buildAuthChangeUrl = (
  url: string,
  linkingProvider?: OAuthLinkProvider,
  redirect?: string,
  asaTaskInfo?: AsaTaskInfo
) => {
  let prefixAdded = false;
  if (linkingProvider) (url += '?link=' + linkingProvider) && (prefixAdded = true);
  if (redirect) (url += `${prefixAdded ? '&' : '?'}redirect=${redirect}`) && (prefixAdded = true);
  if (asaTaskInfo)
    (url += `${prefixAdded ? '&' : '?'}user_id=${asaTaskInfo.asaUserId}&unique_task_key=${
      asaTaskInfo.asaUniqueTaskKey
    }&step_number=${asaTaskInfo.asaStepNumber}&partner_task_reference=${asaTaskInfo.questName}`) &&
      (prefixAdded = true);
  return url;
};
