export const TurnstileRequestHeader = 'x-ender-turnstile';
export const TurnstileErrorResponseHeader = 'x-ender-turnstile-error';

if (!process.env.NEXT_PUBLIC_CF_TURNSTILE_SITE_KEY) {
  throw new Error('NEXT_PUBLIC_CF_TURNSTILE_SITE_KEY environment variable must be set');
}

export const TURNSTILE_SITE_KEY = process.env.NEXT_PUBLIC_CF_TURNSTILE_SITE_KEY;

type Options<T> = Omit<RequestInit, 'body'> & {
  body: T;
  turnstile: undefined | string;
};

export class TurnstileError extends Error {}

export const turnstileFetch = async <T extends undefined | Record<string, unknown>>(
  input: RequestInfo | URL,
  options: Options<T>
): Promise<Response> => {
  const { body, headers: userHeaders, turnstile, ...fetchOptions } = options;
  const headers = new Headers(userHeaders);

  if (!headers.get('Accept')) {
    headers.set('Accept', 'application/json');
  }

  if (turnstile) {
    headers.set(TurnstileRequestHeader, turnstile);
  }

  if (body) {
    headers.set('Content-Type', 'application/json');
  }

  const response = await fetch(input, {
    ...fetchOptions,
    ...(body ? { body: JSON.stringify(body) } : undefined),
    headers,
  });

  const turnstileError = response.headers.get(TurnstileErrorResponseHeader);

  if (turnstileError) {
    throw new TurnstileError(turnstileError);
  }

  return response;
};
