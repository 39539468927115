import { useEffect } from 'react';

import { useToast } from '@/client/features/toast/providers/toast-provider';
import type { ISimpleToast } from '@/client/features/toast/types/simple-toast';
import { useRouter } from '@/hooks/use-compatible-router';
import { OAuthError } from '@/shared/oauth/errors';

const errorToasts: Record<OAuthError, (provider: string) => Omit<ISimpleToast, 'color'>> = {
  [OAuthError.AccountAlreadyLinked]: (provider) => ({
    title: `${provider} account already linked`,
    description: `Another Ender account is already linked to this ${provider} account. You must unlink that account before linking this one.`,
  }),
  [OAuthError.Adult]: () => ({
    title: 'Sorry, Ender is only for kids',
    description: "If you're a parent signed up for your child, please have them use their own account profile instead.",
  }),
  [OAuthError.Child]: () => ({
    title: 'Sorry, only a parent account can do this!',
    description: 'Please ask your parent to complete this action instead.',
  }),
  [OAuthError.InvalidAccountState]: () => ({
    title: `Invalid account state`,
    description: 'Please Contact Support and tell us the username of the account having issues.',
  }),
  [OAuthError.NotAuthenticated]: () => ({
    title: `Not logged in`,
    description: 'Please login and try again.',
  }),
  [OAuthError.NetworkError]: () => ({
    title: 'Network Error',
    description: 'Too many attempts. Please wait a few minutes and try again.',
  }),
  [OAuthError.NoMinecraftProfile]: () => ({
    title: `Issue linking account`,
    description: 'The Microsoft account you are signing in with does not own Minecraft.',
  }),
  [OAuthError.ProviderAuthenticationFailed]: (provider) => ({
    title: 'Account linking failed',
    description: `We were unable to authenticate with your ${provider} account. Please try again.`,
  }),
  [OAuthError.ProviderSignUpForbidden]: (provider) => ({
    title: 'Account not created',
    description: `We no longer allow signing up with ${provider}. Please sign up with username instead.`,
  }),
  [OAuthError.UserNotFound]: (provider) => ({
    title: 'Account not found',
    description: `No Ender account is linked to this ${provider} account.`,
  }),
  [OAuthError.UserUpdateFailed]: (provider) => ({
    title: 'Something went wrong',
    description: `An error occurred while attempting to connect ${provider} to your account. Please try again.`,
  }),
};

export const OAuthErrorToasts = () => {
  const router = useRouter();
  const queryError = router.query.error;
  const queryProvider = router.query.provider;

  const { showSimpleToast } = useToast();

  useEffect(() => {
    const errors = queryError ? (Array.isArray(queryError) ? queryError : [queryError]) : [];

    for (const error of errors) {
      const toast: undefined | (typeof errorToasts)[OAuthError] = errorToasts[error as OAuthError];
      const provider =
        (typeof queryProvider === 'string' && `${queryProvider?.[0]?.toUpperCase() ?? ''}${queryProvider.slice(1)}`) ||
        'Auth';

      if (toast) {
        showSimpleToast({
          color: 'danger',
          ...toast(provider),
        });
      }
    }
  }, [queryError, queryProvider, showSimpleToast]);

  return null;
};
